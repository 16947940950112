import {mapMutations, mapActions, mapGetters} from "vuex";

export default {
    name: "recommendations",
    components: {},
    data() {
        return {
            item:''
        };
    },
    methods: {
        ...mapMutations({
            changeRecommendationPopup: 'profile/CHANGE_RECOMMENDATION_POPUP',
        }),
        ...mapActions({
            getRecommendations: 'auth/GET_RECOMMENDATIONS',
            getCurrentRecommendation: 'auth/GET_CURRENT_RECOMMENDATION'
        }),

    },
    computed: {
        ...mapGetters({
            recommendations: 'auth/recommendations'
        })
    },
    created() {

    }

};
